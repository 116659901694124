<!-- 页面顶部 -->
<template>
    <div class="navTopNew">
        <!--顶部工具栏-->
        <div>
            <div class="mymenu-container">
                <div class="mymenu-container-left">
                    <div @click="$oucy.go('/', null)" class="top-nav-title df-ai-c pointer" style="">
                        <img src="@/assets/images/logo_w.png" height="50px" alt="log">
                        <h3 class="f24 m-l-25 title-text">家居供应链服务平台</h3>
                    </div>
                    <div class="df m-l-15">
                        <div class="m-r-20 webid"><span class="m-r-10 top-nav-title"><i class="el-icon-location-information"></i>当前站点:</span>
                            <el-select v-model="siteId" placeholder="请选择" size="mini" @change="changeSite">
                                <el-option label="全部" value="null"></el-option>
                                <el-option v-for="item in sites" :key="item.id" :label="item.siteName" :value="item.id"></el-option>
                            </el-select>
                        </div>

                    </div>
                </div>
                <!--左右分割-->
                <div>
                    <div v-if="isLogin">
                        <span class="el-dropdown-link user" @click="go('/acc/userinfo')">
                            <template v-if="userInfoEntity">
                                <img v-if="userInfoEntity && userInfoEntity.detailAvatar" :src="this.$oucy.ossUrl+userInfoEntity.detailAvatar" class="detailAvatar">
                                <span :title="$oucy.getUserName(userInfoEntity).name">{{$oucy.getUserName(userInfoEntity).nameSubstr}}</span>
                            </template>
                        </span>
                        <span class="el-dropdown-link logout" @click="logout">
                            [退出]
                        </span>
                        <span class="el-dropdown-link" @click="openMessage" v-if="allMessageCount>0">
                            消息({{allMessageCount}})
                        </span>
                        <span class="el-dropdown-link" @click="go('/acc/StockCar')">
                            进货车({{stockCarNum}})
                        </span>
                        <span>
                            <el-dropdown size="mini">
                                <span class="el-dropdown-link" @click="go('/acc/userinfo')">
                                    用户中心
                                    <i class="el-icon-caret-bottom el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>
                                        <div @click="go('/acc/userinfo')">个人中心</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="go('/ReleaseCenterAskBuySave')">发布求购</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="go('/askBuyManagement')">求购管理</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="go('/acc/OMS')">订单管理</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="go('/clubCard')">会员中心</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="go('/Address')">收货地址</div>
                                    </el-dropdown-item>
                                    <!-- <el-dropdown-item disabled>我的收藏</el-dropdown-item> -->
                                    <!-- <el-dropdown-item @click.native="logout">退出账号</el-dropdown-item> -->
                                </el-dropdown-menu>
                            </el-dropdown>
                        </span>
                        
                        <span v-if="+userInfoEntity.userIdentity === 3">
                            <el-divider direction="vertical"></el-divider>
                            <el-dropdown size="mini">
                                <span class="el-dropdown-link" @click="go('/acc/enterprise')">
                                    企业管理
                                    <i class="el-icon-caret-bottom el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>
                                        <div @click="go('/acc/enterprise')">企业中心</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="go('/enter/OMS')">企业订单</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="go('/enter/product')">商品管理</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="go('/acc/enterprise')">内容管理</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="go('/enter/CouponManagement')">优惠券</div>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <el-divider direction="vertical"></el-divider>
                        </span>
                        
                        <span>
                            <el-dropdown size="mini">
                                <span class="el-dropdown-link">
                                    快速导航<i class="el-icon-caret-bottom el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>
                                        <div @click="go('/liveList')">直播中心</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="go('/receiveCoupon')">领券中心</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="go('/download')">APP下载</div>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </span>
                    </div>
                    <div class="top-nav-entrance" v-else>
                        <span @click="showLoginDialog" class="pointer">注册或登录</span>
                        <span>
                            <el-dropdown size="mini">
                                <span class="el-dropdown-link">
                                    快速导航<i class="el-icon-caret-bottom el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>
                                        <div @click="go('/liveList')">直播中心</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="go('/receiveCoupon')">领券中心</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div @click="go('/download')">APP下载</div>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <register :isVisible="dialog_regiform_visible" @close="handleRegisterClose" @goLogin="handleGoLogin" />
        <login :isVisible="dialog_loginform_visible" @loginSuccess="handleLoginSuccess" @openBindPhone="openBindPhone" @goRegister="handleGoRegister" @close="handleLoginClose" />
        <bind-phone :isVisible="isShowBindPhone" @closeBindPhone="closeBindPhone"></bind-phone>
    </div>
</template>
<script>
import oucy from "../util/oucyUtil"
import register from './Register.vue'
import login from './Login.vue'
import bindPhone from './bindPhone.vue'
import { localSet, localGet, localDel } from "../store/store";
import { logicSite, dataNum, userMaterial, message } from "@/service"

export default {
    name: 'navTopNew',
    components: {
        register,
        bindPhone,
        login
    },
    data() {
        return {
            isLogin: false,
            userInfoEntity: null,
            dialog_regiform_visible: false,
            dialog_loginform_visible: false,
            pageWidth: oucy.pageWidth,
            siteId: '全部',
            isShowBindPhone: false,
            sites: [],
            stockCarNum: null,
            allMessageCount: 0
        }
    },
    computed: {
        statu() {
            return this.$store.state.statu
        },
        hasEnter() {
            return (this.$route.path.includes('enter') || this.$route.path.includes('spupgrade')) && !this.$route.path.includes('ReleaseCenter')
        }
    },
    mounted() {
        this.getAllLogicSite()
        const me = this;
        //初始化登录数据
        this.initLoginStatus();
        //监听登录事件
        me.eventBus.$on(me.$oucy.msgKeys.UPDATA_STOCK_CAR_NUM, (res) => {
            console.log('更新进货车数量');
            me.queryStockNum()
        });
        //监听登录事件
        me.eventBus.$on(me.$oucy.msgKeys.LOCAL_LOGIN, (res) => {
            console.log('登录事件：');
            me.isLogin = false
            me.showLoginDialog();
        });
        //监听更新登录用户数据事件
        me.eventBus.$on(me.$oucy.msgKeys.UPDATE_LOGIN_USER_INFO, (res) => {
            console.log('更新登录用户数据事件：');
            me.initLoginStatus();
            me.queryStockNum()
        });
        this.eventBus.$on(this.$oucy.msgKeys.IM_MESSAGE_NO_READ_COUNT, (res) => {
            this.setAllMessageCount(res)
        });
        this.eventBus.$on(this.$oucy.msgKeys.SYSTEM_ALL_READ_MESSAGE, (res) => {
            this.setAllMessageCount(res)
        });
        this.eventBus.$on(this.$oucy.msgKeys.CHECKFRIENDSNUM, (res) => {
            this.setAllMessageCount(res)
        });
        this.loginUser = localGet(this.$oucy.userInfoKey) || {}
        setTimeout(() => {
            if (this.$oucy.getToken() && this.loginUser.id) {
                this.getAllNoReadMessageCount()
            }
        }, 2000)
    },
    methods: {
        openMessage() {
            console.log(666)
            this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE, null);
        },
        setAllMessageCount(res) {
            if (res) {
                this.allMessageCount = res.allNoReadCount
            }
        },
        getAllNoReadMessageCount() {
            message.getAllNoReadMessageCount({}).then(res => {
                this.allMessageCount = res || 0
            })
        },
        showRegiDialog() {
            this.dialog_regiform_visible = true;
        },
        handleRegisterClose: function() {
            this.dialog_regiform_visible = false;
        },
        showLoginDialog() {
            this.dialog_loginform_visible = true;
        },
        handleLoginClose: function() {
            this.dialog_loginform_visible = false;
        },
        handleGoRegister: function() {
            this.handleLoginClose();
            this.showRegiDialog();
        },
        handleGoLogin: function() {
            this.handleRegisterClose();
            this.showLoginDialog();
        },
        handleLoginSuccess: function() {
            this.handleLoginClose();
            this.initLoginStatus();
        },
        logout: function() {
            const me = this;
            this.$confirm('即将退出登录, 是否继续?', '提示', {
                confirmButtonText: '继续退出',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                oucy.logout();
                me.initLoginStatus();
                oucy.app.$router.replace("/").then(res => {
                    location.reload();
                });
                localDel('enterprise')
                this.$message({
                    type: 'success',
                    message: '退出成功!'
                });
                oucy.receiveUserRecordEvent({ record: '退出登录' })
                oucy.app.eventBus.$emit(oucy.msgKeys.LOGOUT_SUCCESS, null);
            }).catch(() => {});

        },
        initLoginStatus: function() {
            const me = this;
            let userInfoEntity = oucy.getLocalUserEntity();
            if (oucy.getToken() && userInfoEntity != null) {
                userMaterial.queryUserMaterial().then(res => {
                    if (res && res.id) {
                        me.isLogin = true;
                        me.userInfoEntity = res;
                        me.queryStockNum()
                        /*                        if(oucy.thisUUID!=res.id){
                                                   oucy.thisUUID=res.id
                                                   oucy.initMQTT();
                                                   console.log('重新更新 MQTT')
                                                }*/

                    } else {
                        oucy.removeToken()
                        me.isLogin = false;
                        me.userInfoEntity = null;
                        localDel(this.$oucy.userInfoKey);

                    }
                })
            } else {
                localDel(this.$oucy.userInfoKey);
                me.isLogin = false;
                me.userInfoEntity = null;
            }
        },
        go: function(path) {
            this.$router.push(path)
        },
        getAllLogicSite() {
            logicSite.getAllLogicSite({ siteLatitude: null, siteLongtitude: null, a: 123 }).then(res => {
                this.sites = res || []
                let siteId = localGet('siteId')
                if (siteId) {
                    this.siteId = siteId
                }
                // this.sites.unshift({siteName:'全部',id:null})
            })
        },
        changeSite() {
            if(this.siteId){
                localSet('siteId', this.siteId)
            }else{
                this.siteId=null
                localDel('siteId')
            }
            let path = this.$route.path
            oucy.receiveUserRecordEvent({ record: '切换站点' + this.siteId })
            if (path == '/furniture' || path == '/material' || path == '/enterprise' || path == '/company') {
                this.$confirm('你已切换站点, 是否重新加载数据?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    location.reload();
                }).catch(() => {

                });
            }
        },
        openBindPhone() {
            this.isShowBindPhone = true
        },
        closeBindPhone() {
            this.isShowBindPhone = false
        },
        queryStockNum() {
            dataNum.queryStockNum({}).then(res => {
                this.stockCarNum = res || 0
            })
        },

    }
}
</script>
<style scoped>
.top-nav-title {
    font-size: 12px;
    color: #fff;
}

.top-nav-hello {
    text-align: left;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #fff;
}

.top-nav-hello img {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin: 0 5px 0 0;
}

.top-nav-hello a {
    text-decoration: none;
    color: #9fff
}

.top-nav-entrance {
    text-align: right;
    color: #fff;
}

.top-nav-entrance span {
    padding: 0 3px;
    /*color: green;*/
    font-size: 12px;
}

.el-dropdown-link {
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    padding: 0 6px;

}

.el-icon-caret-bottom {
    font-size: 12px;
}

.mymenu-container {
    /*height: 30px;*/
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
}

.mymenu-container-left {
    display: flex;
    align-items: center;
}

.top-nav-hello {
    text-align: left;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.detailAvatar {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -2px;
}
.logout{
    color: #BFDDF9;
}
.top-nav-hello a {
    text-decoration: none;
    color: #2090FF;
}

.el-icon-caret-bottom {
    font-size: 12px;
}
.el-icon--right{
    margin-left: 0;
}

.mymenu-container {
    /*line-height: 50px;*/
    margin: 0 auto;
}

.mymenu {
    padding: 0;
    background-color: #ffffff;
    margin-top: 50px;
}



.el-button--text {
    color: black;
}

.webid /deep/ .el-input__inner {
    border: 0px solid #2a2c33;
    border-color: #f5f5f5;
    padding-left: 6px;
    padding-right: 4px;
    width: 52px;
    color: #fff;
    background: rgba(255, 255, 255, 0.21);
}

.webid /deep/.el-input__suffix {
    right: 0;
}

.webid /deep/ .el-input--mini .el-input__inner {
    height: 20px;
    line-height: 20px;
}

.webid /deep/ .el-input__icon {
    line-height: 20px;
}

.icon {
    width: 22px;
    height: 22px;
    vertical-align: -0.3em;
    fill: currentColor;
    overflow: hidden;
}

.qyicon {
    width: 14px;
    height: 14px;
    vertical-align: -0.25em;
    fill: currentColor;
    overflow: hidden;
}

.fbicon {
    width: 22px;
    height: 22px;
    vertical-align: -0.5em;
    fill: currentColor;
    overflow: hidden;
}

@media screen and (max-width: 1400px) {
    .title-text {
        display: none;
    }
}


.el-select>.el-input{
  width: auto;
}
</style>
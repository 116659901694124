<template>
    <div class="homeIndexTop text-center">
        <div class="nav-background">
<!--          <div @click="topToLink">-->
<!--            <img src="@/assets/images/index/top_ad2.png" style="width: 100%;cursor:pointer">-->
<!--          </div>-->
            <div class="max1600_min1200">
                <navTopNew></navTopNew>
                <!--图标logo-->
                <div style="padding: 70px 0 50px 0">
                    <h1>专业为B端集采服务的平台</h1>
                    <div class="title2">设计师 <el-divider direction="vertical"></el-divider> 经销商 <el-divider direction="vertical"></el-divider> 专卖店 <el-divider direction="vertical"></el-divider> 渠道商 <el-divider direction="vertical"></el-divider> 贸易商</div>
                    <!-- <img style="width: 300px;" src="../../assets/logo.png" class="pointer" @click="$oucy.go('/')"> -->
                </div>
                <!--搜索 组件-->
                <searchProductNew></searchProductNew>
            </div>
        </div>
        <div class="bg_f">
            <div class="max1600_min1200 ">
                <!--选项卡-->
                <div class="mynavi-menu pointer">
                    <div @click="goto('/', null)" class="mynavi-menu-ele" :class="path=='/'?'select':''">首页</div>
                    <div @click="goto('/askBuyList', null)" class="mynavi-menu-ele" :class="path=='/askBuyList'||path=='/SearchAskBuy'?'select':''">求购库</div>
                    <div @click="goto('/furniture', null)" class="mynavi-menu-ele" :class="path=='/furniture'||path=='/SearchFurniture'?'select':''">家具库</div>
                    <div @click="goto('/material', null)" class="mynavi-menu-ele" :class="path=='/material'||path=='/SearchMaterial'?'select':''">材料库</div>
                    <div @click="goto('/company', null)" class="mynavi-menu-ele" :class="path=='/company'||path=='/searchenterprise'?'select':''">企业库</div>
                    <div @click="goto('/card', null)" class="mynavi-menu-ele" :class="path=='/card'|| path=='/SearchCard'?'select':''">名片库</div>
                    <!-- <div class="mynavi-menu-ele" :class="path==''?'select':''">物流库</div> -->
                </div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>
<script>
import navTopNew from "@/components/navTopNew.vue";
import { spu } from "@/service"
import oucy from "../../util/oucyUtil";
export default {
    name: "Index",
    components: { navTopNew },
    data() {
        return {
            pageWidth: oucy.pageWidth,
            searchClassify: "furniture",
            searchClassifyVal: "",
            path: '',
            restaurants: [],
            keyword: '',
            timeout: null,
            category: 0,
        }
    },
    watch: {
        $route: {
            handler(to, from) {
                this.path = to.path
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {},
    methods: {
        // 切换分类触发
        setCategory() {
            if (this.searchClassify == 'furniture') {
                this.category = 0
            } else
            if (this.searchClassify == 'material') {
                this.category = 1
            }
        },
        goto(path) {
            if (path == this.path) return
            this.$oucy.replace(path, null)
        },
        topToLink(){
          window.open('https://mp.weixin.qq.com/s/3N78YIHbAXVFRxWpWLK5Jw')
        },
        // 关键字触发
        querySearchAsync(queryString, cb) {
            this.timeout = setTimeout(() => {
                spu.searchProductSuggest({ category: this.category, keyword: queryString }).then(res => {
                    var restaurants = []
                    if (res && res.suggestions) {
                        for (let v of res.suggestions) {
                            restaurants.push({ value: v })
                        }
                    }
                    cb(restaurants);
                })
            }, 100);
        },

    }
}
</script>
<style scoped>
.homeIndexTop .nav-background {
    /*background-image: url("../../assets/images/header_bg.png");*/
    background-image: url("../../assets/images/headerNewBg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 100px;
    min-width: 1200px;
}

.homeIndexTop .input-container {
    /*width: 700px;*/
    /*margin: 0 auto;*/
}


.homeIndexTop .input-with-select .el-input-group__prepend {
    background-color: #fff;

}

.mynavi-menu {
    padding: 0;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    margin-top: -1px;
    text-align: center;
}

.mynavi-menu-ele {
    flex: 1;
    padding: 30px;
    font-size: 16px;
    font-family: "Microsoft YaHei";
    cursor: pointer;
}

.mynavi-menu-ele.select,
.mynavi-menu-ele:hover {
    color: #0366c3;
    /*background-color: #2090ff;*/
    background: linear-gradient(180deg, #2090ff, #6e6eff);
    color: #fff;
}

h1 {
    font-size: 46px;
    font-family: MicrosoftYaHei-Bold;
    text-align: center;
    color: #ffffff;
    line-height: 63px;
    letter-spacing: 9.2px;
}

.bg_f {
    background: #fff;
}
.title2{
    opacity: 0.75;
    font-size: 16px;
   color: #fff;
   margin-top: 20px;
}
</style>